export const dateForView = (date) => {
  if(date.includes('T')) {
    const parseFullDate = date.split('T');
    const timeParse = parseFullDate[1].split('+');
    const timeWithOutMilliseconds = timeParse[0].split('.');
    const parseDate = parseFullDate[0].split('-');
    return `${parseDate[0]}/${parseDate[1]}/${parseDate[2]} ${timeWithOutMilliseconds[0]}`

  } else {
    const parseFullDate = date.split(' ');
    const parseDate = parseFullDate[0].split('-');
    return `${parseDate[0]}/${parseDate[1]}/${parseDate[2]} ${parseFullDate[1]}`
  }
}

export const dateForSend = (date) => {
  const parseFullDate = date.split(' ');
  const parseDate = parseFullDate[0].split('/');
  return `${parseDate[0]}-${parseDate[1]}-${parseDate[2]} ${parseFullDate[1]}`
}