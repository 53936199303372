
	import {mapActions, mapGetters, mapState} from 'vuex';
	import gtmButtonClick from '@/mixins/gtm-button-click';
	import {differenceInYears, differenceInDays, differenceInHours, formatDistanceStrict, formatRelative, endOfTomorrow} from 'date-fns';
	import format from "date-fns/format";
	import locale from 'date-fns/locale/ru';
	import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
	import {dateForView} from "@/helpers/dateFormat";

	import methodsDescriptions from "@/helpers/methodsDescriptions";

	import Badge from "@/components/ui-kit/Badge.vue";
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import ButtonLink from "@/components/ui-kit/buttons/ButtonLink";
	import UiButton from '@/components/ui-kit/buttons/Button';
	import Tooltip from "@/components/ui-kit/Tooltip";
	import {DEFAULT_COST_CATEGORY_IDS} from "@/constants/sessions";

	export default {
		name: 'SpecialistCard',
		components: {Tooltip, ButtonLink, UiButton, UiIcon, Badge},
		mixins: [gtmButtonClick],
		props: {
			item: {
				type: Object, default: () => {
				}
			},
			index: Number
		},
		data() {
			return {
				maxPreferredIssuesCount: 4
			}
		},
		computed: {
			...mapState('auth', ['isAuth']),
			...mapState('user', ['timeZone']),
			...mapGetters({
				getSessionDurationMinutes: 'globalSettings/getSessionDurationMinutes',
			}),
			durationSession() {
				const ds = this.getSessionDurationMinutes
				if (!ds) return 'Не указано'
				return `${ds} мин.`
			},
			photoUrl() {
				return this.item?.photo?.bucket_url
			},
			photoName() {
				return this.item?.photo?.name
			},
			age() {
				return differenceInYears(new Date(), new Date(this.item?.date_of_birth));
			},
			clientAnswers() {
				return this.$store.state.question.clientAnswers?.results || [];
			},
			answerBadges() {
				const answers = [];
				this.clientAnswers.forEach(item => {
					if (item.question_slug !== 'cost_category' && item.question_slug !== 'timezone') {
						if (item.multiple) {
							answers.push(...item.answer.map(answer => answer.name));
						} else {
							answers.push(item.answer.name);
						}
					}
				})

				return answers;
			},

			methodsDescriptions() {
				return methodsDescriptions
			},

			therapyMethods() {
				return this.item.therapy_methods;
			},

			isShowPrice() {
				return !this.isAuth || this.$store.state.user.isNotB2BUser;
			},

			firstSessionPromocode() {
				return this.$store.state.user.promocodes.find(promocode => {
					return promocode.only_first_session;
				})
			},

			isShowFirstSessionPrice() {
				if (this.isAuth) {
					return DEFAULT_COST_CATEGORY_IDS.includes(this.item.cost_category.id) && this.firstSessionPromocode;
				} else {
					return DEFAULT_COST_CATEGORY_IDS.includes(this.item.cost_category.id);
				}
			},
			diffHoursToClosestSlot() {
				if(this.item.next_session_date) {
					const today = utcToZonedTime(zonedTimeToUtc(new Date()), this.timeZone);
					const next_session_date = utcToZonedTime(this.item.next_session_date, this.timeZone);
					return  differenceInHours(next_session_date, today);
				}

				return null
			},
			closestSlotDisplayName () {
				let text = '';

				if(this.diffHoursToClosestSlot) {
					const today = utcToZonedTime(zonedTimeToUtc(new Date()), this.timeZone);
					const dateEndOfTomorrow =  utcToZonedTime(zonedTimeToUtc(endOfTomorrow()), this.timeZone);
					const next_session_date = utcToZonedTime(this.item.next_session_date, this.timeZone);

					const diffHours = this.diffHoursToClosestSlot;//differenceInHours(next_session_date, today);
					const diffHoursEndOfTomorrow = differenceInHours(dateEndOfTomorrow, today);

					if(diffHours <= 4) {
						text = `через ${formatDistanceStrict(next_session_date, today, {locale, unit: 'hour'})}`
					} else if(diffHours <= diffHoursEndOfTomorrow){
						text = formatRelative(next_session_date, today, {locale});
					} else {
						text = format(next_session_date, 'd MMMM p', {locale})
					}

					console.log(diffHoursEndOfTomorrow);
				}

				return text;
			}
		},
		methods: {
			...mapActions({
				getSettingByKey: 'globalSettings/getSettingByKey',
			}),
			price(val) {
				if (val) {
					const formatter = new Intl.NumberFormat('ru')
					return `${formatter.format(val)}  руб.`
				}
				return 'Не указано'
			},

			// getProfileRoute(id, slotDate) {
			// 	return {
			// 		name: 'search-psychologist-details',
			// 		query: {id},
			// 		query: {slotDate},
			// 	}
			// },

			onCardClick(item, openVideo = false, scrollToCalendar = false) {
				try {
					if (openVideo) {
						this.gtmButtonClick(
							'videopsy',
							'searchpsy',
							'button',
							'click',
							'clientPage'
						);
					} else {
						this.gtmButtonClick(
							'card_psy',
							scrollToCalendar ? 'sign_up_session' : 'psy_more',
							'button',
							'click',
							'clientPage'
						);
					}
				} catch (e) {
					console.log(e)
				}

				this.$store.commit('psychologist/setFromTherapistSearch', true);
				this.$store.commit('psychologist/setIsAdditionalTherapistSearch', this.index > this.$store.state.rubricator.pageSize - 1);

				this.$router.push({
					name: item.isPsychologist ? 'search-psychologist-details' : 'search-others-details',
					query: {
						id: item.psychologist_id,
						slotDate: item.next_session_date,
						search_id: item.search_id,
						specialization_type: item.specialization_type,
						openVideo,
						scrollToCalendar
					},
				})
			},
		},
	}
