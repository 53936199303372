const methodsDescriptions = {
	"Когнитивно-поведенческая терапия":
		{
			key: 'Когнитивно-поведенческая терапия',
			title: 'Когнитивно-поведенческая терапия (КПТ)',
			tooltip: "Метод фокусируется на коррекции негативных мыслительных стереотипов и поведенческих реакций. Подумайте об этом как о тренажерном зале для вашего разума. КПТ часто используется для работы с тревожными и депрессивными состояниями.",
		},
	"Клиент-центрированная психотерапия":
		{
			key: 'Клиент-центрированная психотерапия',
			title: 'Клиент-центрированная терапия (КЦТ)',
			tooltip: "В этом подходе клиент сам является экспертом своей жизни. Здесь терапевт – лишь ассистент в создании портрета вашего пути. Метод основан на принципах эмпатии, безусловного принятия и подлинности – способности всегда оставаться подлинным собой.",

		},
	"Гештальт-терапия":
		{
			key: 'Гештальт-терапия',
			title: 'Гештальт',
			tooltip: "Подход фокусируется на состоянии «здесь и сейчас» и помогает осознать текущие переживания. Процесс похож на чтение книги, где каждая страница — это момент вашей жизни. В работе гештальт-терапевты используют диалог с клиентом, техники и эксперименты.",
		},
	"Гипноз":
		{
			key: 'Гипноз',
			title: 'Гипнотерапия',
			tooltip: "Гипнотерапия позволяет достичь состояния глубокой релаксации и работать с подсознанием. Это словно путешествие на подводной лодке в глубины внутреннего мира. Метод часто используется для коррекции поведения и проработки травматических воспоминаний.",
		},
	"Юнгианский анализ":
		{
			key: 'Юнгианский анализ',
			title: 'Юнгианский анализ',
			tooltip: "Метод исследует глубокие слои сознания (архетипы), содержащие в себе ресурсы и страхи. Представьте вашу психику как карту звездного неба со многими символами. Юнгианский анализ часто использует сновидения как источник информации о вашей личности.",
		},
	"Психодрама":
		{
			key: 'Психодрама',
			title: 'Психодрама',
			tooltip: "Подход использует драматургию и ролевые игры для исследования личных конфликтов. Это похоже на импровизационный театр вашей жизни. Психодрама часто применяется для эффективного разрешения межличностных проблем.",
		},
	"Телесная терапия":
		{
			key: 'Телесная терапия',
			title: 'Телесно-ориентированная терапия (ТОТ)',
			tooltip: "Подход сочетает психоанализ и другие психотерапевтические техники с физическими упражнениями для освобождения зажимов и напряжений в теле. Подумайте о вашем теле как о хранилище эмоций, которые нужно выпустить на свободу.",
		},
	"Танцевально-двигательная терапия":
		{
			key: 'Танцевально-двигательная терапия',
			title: 'Танцевально-двигательная терапия (ТДТ)',
			tooltip: "Метод использует движение как средство самовыражения и самопознания. Подумайте о танце как о языке, которым ваше тело говорит с вами и миром. Танцевально-двигательная терапия может быть полезна в лечении эмоциональных и физических трудностей.",
		},
	"Психоанализ":
		{
			key: 'Психоанализ',
			title: 'Психоанализ',
			tooltip: "Метод исследует глубоко укорененные причины поведения или состояний и высвобождает блокированную энергию. Представьте, что это археологические раскопки вашего прошлого. Психоанализ использует такие техники, как анализ снов и свободные ассоциации.",

		},

	"Арт-терапия":
		{
			key: 'Арт-терапия',
			title: 'Арт-терапия',
			tooltip: "Арт-терапия основана на психоаналитических теориях и раскрывает подсознательные эмоции и мысли. Метод использует искусство и творчество как средство самовыражения, самопознания и трансформации. Представьте, что вы разговариваете с собой через холст.",

		},
	"Семейная терапия":
		{
			key: 'Семейная терапия',
			title: 'Семейная',
			tooltip: "Подход рассматривает семью как систему взаимоотношений. Подумайте о семье как об оркестре, где каждый участник связан с другими. Метод позволяет выявить и измененить негативные паттерны взаимодействия между родными людьми.",
		},
	"Десенсибилизация и переработка при помощи движения глаз":
		{
			key: 'Десенсибилизация и переработка при помощи движения глаз',
			title: 'Десенсибилизация и переработка движением глаз (EMDR)',
			tooltip: "Метод используется в работе с травмами и фобиями через специфические движения глаз. Представьте, что ваша память — это фотоальбом, и ДПДГ помогает «перефотографировать» травматические моменты."

		}
}

export default methodsDescriptions
