
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import ClickOutside from 'vue-click-outside'

	export default {
		name: "Tooltip",
		props: {
			position: {
				validator(value) {
					return ['left', 'right'].includes(value)
				},
				default: 'right'
			},
			isAbsolute: Boolean,
			icon: {
				type: String,
				default: 'icons20/info'
			},
			iconClass: String,
			externalOpen: Boolean
		},
		components: {UiIcon},
		directives: {
			ClickOutside,
		},
		data() {
			return {
				isOpened: false,
			}
		},
		computed: {
			iconClasses () {
				return this.iconClass + (this.isAbsolute ? ' absolute left-4 top-0': '')
			},
			tooltipClasses () {
				return this.position === 'right' ? '-left-28 before:-bottom-[8px] before:left-[30px] ': '-right-28 before:-bottom-[8px] before:right-[30px]'
			}
		},
		methods: {
			open() {
				this.isOpened = true
			},
			close() {
				if (this.isOpened) {
					this.isOpened = false
				}
			}
		}
	}
