
	export default {
		name: "SimpleBadge",
		props: {
			type: {
				validator(value) {
					return ['simple', 'button'].includes(value)
				},
				default: 'simple'
			},
			color: {
				validator(value) {
					return ['white', 'gray', 'gray-outline', 'orange', 'success', 'error', 'blue-text'].includes(value)
				},
				default: 'white'
			},
			extraClasses: {type: Object, default: () => ({})}
		},
		computed: {
			classes() {
				return {
					'border-[1px] text-secondary transition-[0.3s] hover:border-secondary': this.type === 'button' && !this.color,
					'border-[1px] bg-white border-white shadow-badge': this.color === 'white',
					'border-[1px] bg-[#F5F5F8] border-[#F5F5F8] text-black': this.color === 'gray',
					'border-[1px] bg-[#F5F5F8] border-[#F5F5F8] text-primary': this.color === 'blue-text',
					'border-[1px] border-[#DDD]': this.color === 'gray-outline',
					'bg-[#FFF3E9] text-[#EF7918]': this.color === 'orange',
					'bg-[#F1F9F8] text-[#3DAE9F]': this.color === 'success',
					'bg-[#FFF2F2] text-[#EA4B4B]': this.color === 'error',
					'px-12 py-[4px] font-t12r min-h-[28px] rounded-[18px] flex items-center': true,
					...this.extraClasses,
				}
			}
		}
	};
